<template>
  <div>
    <v-row>
      <v-col cols="auto">
        <v-alert
          dense
          height="34"
          class="bg-altuny-tonal altuny--text d-flex align-center"
          :border="$vuetify.rtl ? 'left' : 'right'"
        >
          <span class="text-h6">{{ apiData.length }}</span> {{ $t('Company') }}
        </v-alert>
      </v-col>

      <v-col cols="auto">
        <v-btn
          class="ms-3"
          depressed
          small
          color="primary"
          @click="addNew()"
        >
          {{ $t('Add') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col
        v-for="(data, index) in apiData"
        :key="data.id"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="2"
      >
        <v-card
          flat
          outlined
          class="rounded-lg"
        >
          <v-card-text>
            <v-row
              align="center"
              style="position: relative"
            >
              <div
                style="position: absolute; top: 2px; opacity: 0.7"
                :style="$vuetify.rtl ? 'left: 4px' : 'right: 4px'"
              >
                #{{ index + 1 }}
              </div>
              <v-col
                cols="auto"
                class="px-1"
                style="position: relative"
              >
                <v-badge
                  :content="getCompanyNotificationCount(data.id)"
                  :value="getCompanyNotificationCount(data.id)"
                  color="error"
                  style="position: absolute; top: 8px"
                  :style="$vuetify.rtl ? 'right: 6px' : 'left: 6px'"
                />
                <v-avatar size="55">
                  <img :src="`${data.logo}?token=${$_auth_token()}`">
                </v-avatar>
              </v-col>
              <v-col cols>
                <div class="d-flex align-center">
                  <div class="text-h6 text-center w-100 font-weight-bold">
                    {{ data.name }}
                  </div>
                </div>

                <div class="d-flex justify-center mt-3">
                  <v-btn
                    small
                    depressed
                    class="me-3"
                    @click="updateRow(data)"
                  >
                    {{ $t('Edit') }}
                  </v-btn>

                  <v-btn
                    small
                    depressed
                    class3="bg-altuny-tonal altuny--text"
                    :to="`company/${data.id}/`"
                  >
                    {{ $t('View') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!------------------- Dialogs ------------------->
    <add-edit
      :add-edit-dialog="addEditDialog"
      :form-data="formData"
      :edit-mode="editMode"
      @close="addEditDialog = false"
      @reset-table-data="getData()"
      @update-table-data="getData()"
    />
  </div>
</template>

<script>
import AddEdit from './AddEdit.vue'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  components: {
    AddEdit
  },

  data() {
    return {
      addEditDialog: false,
      formData: {},
      editMode: false,
      apiData: [],
      endPoint: 'company/'
    }
  },

  computed: {
    ...mapGetters([
      'companiesNotification',
    ]),
  },

  mounted() {
    this.$_section_title({ title: 'Companies', icon: 'mdil-factory' })
    this.getData()
    this.getBanksList()
    this.getCompanyTypesList()
    this.getGovernorateList()
  },

  methods: {
    ...mapActions([
      'getBanksList',
      'getCompanyTypesList',
      'getGovernorateList',
    ]),

    getCompanyNotificationCount(id) {
      const found = this.companiesNotification.count_per_company.find(el => el.company_id == id)
      return found ? found.count : 0
    },

    getData() {
      axios.get('company/').then((res) => {
        this.apiData = res.data
      })
    },

    addNew() {
      this.formData = {}
      this.editMode = false
      this.addEditDialog = true
    },

    updateRow(data) {
      this.formData = {
        ...data,
        company_type_id: { text: data.company_type.name, value: data.company_type.id },
        governorate_id: { text: data.governorate.name, value: data.governorate.id },
        first_bank_id: { text: data.first_bank.name, value: data.first_bank.id },
        account_bank_id: { text: data.account_bank.name, value: data.account_bank.id },
      }
      this.editMode = true
      this.addEditDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
</style>